import {debounce, safeRedirect} from "@/helpers";

export default {
    computed: {
        debounceWebCamRedirect() {
            return debounce(() => {
                this.$store.dispatch('profile/getWebCamUrl')
                    .then(res => {
                        // let url = encodeURIComponent(res.data.url)
                        // window.open(window.location.origin+'/redirect-url?url='+url, '_blank')
                        safeRedirect(res.data.url)
                    }, res => {
                        console.log(res)
                        this.$router.push({name: 'home'})
                    })
            }, 400)
        }
    }
}
