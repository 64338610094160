export default {
	getNewFormValues() {
		return Vue.http.get(`api/get-new-form-values`)
	},
	getEmailAutoFillDomains(country_id) {
		return Vue.http.get(`api/get-email-autofill-domains/${country_id}`)
	},
	getFaqItems() {
		return Vue.http.get(`api/get-faq-items`)
	},
	getContactItems() {
		return Vue.http.get(`api/get-faq-items/contact`)
	},
	getPage(s_page) {
		return Vue.http.get(`api/get-page/${s_page}`)
	},
	getCountryId() {
		return Vue.http.post(`api/get-geo-country-id`)
	},
	getProvinces(country_id) {
		return Vue.http.get(`api/get-provinces/${country_id}`)
	},
	getCities(province_id) {
		return Vue.http.get(`api/get-cities/${province_id}`)
	},
	getCityPages(province_id) {
		return Vue.http.get(`api/get-city-pages`)
	},
	sendSupportMessage(payload) {
		return Vue.http.post(`api/send_support_message`, payload)
	},
	saveIpqsResults(payload) {
		return Vue.http.post(`api/save-ipqs-results`, payload)
	},
	getInboxSpamUrl(payload) {
		return Vue.http.get(`api/get-inbox-spam-url`, {params: payload})
	},
	getBanners(payload) {
		return Vue.http.get(`api/get-banners`)
	},
	getMarketingPartners() {
		return Vue.http.get(`api/marketing-partners`)
	},
	getDeletedUserLink() {
		return Vue.http.get(`api/get-deleted-user-link`)
	},
  saveAnonymousClick(data) {
    return Vue.http.post(`/anonymous-click`, data)
  },
	saveFingerprint(payload) {
		return Vue.http.post(`api/fp`, {fingerprint: payload})
	}
}
